import React from 'react';
import './SuccessWindow.css';
import ShroomsComingImg from "../UGLY-incoming.gif";

function WalletMenu(props) {
    return (props.trigger) ? (
        <div className='outSideSuccessWindow'>
            <div className='mainSuccessWindow-Body slide-in-bottom'>
                <button className='close-btn' onClick={() => props.setTrigger(false)}>  X  </button>
                
                <div className='successMsgFont'>Transaction submitted successfully.<br/> $UGLY will arrive shortly.</div>
                <img className='successMsgImage' src={ShroomsComingImg} />
                { props.children }
            </div>
        </div>
    ) : "";
}

export default WalletMenu;