import React from "react";
import "./HeaderComponent.css"
import logo from "../UB Logo.png";
import  * as WASM from '@emurgo/cardano-serialization-lib-browser' //'./local_packages/cardano-serialization-lib-nodejs/cardano_serialization_lib.d.ts';
import {Buffer} from "buffer";
import initCardanoDAppConnectorBridge from "./cardano-dapp-connector-bridge.js";

import { useState, useEffect } from "react";
import pageTitleImg from '../ugly-atm-title.png'
import WalletMenu from "./WalletsMenu";
import WalletDropDown from "./WalletDropDown";

import BufferWindow from './BufferWindow';

import loadingIcon from "../Loading.gif";



let currentActiveWallet;

let useClickOutsite = (handler,elementClass) => {

    useEffect(() => {
        let maybeHandler = (event) => {
                if(event.target.className.toString()==elementClass.toString()){
                    handler();
                }
        };
        document.addEventListener("mousedown", maybeHandler);
           
        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });
}


async function getWalletAddress(walletType)  {
    
    const WalletInterface = await getWalletInterface(walletType);
    let walletAddress="";
    const usedAddresses =  await  WalletInterface.getUsedAddresses();
    let changeAddress="";
    if(usedAddresses.length>0)
        changeAddress = WASM.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
    else{
        const unusedAddresses = await WalletInterface.getUnusedAddresses();
        changeAddress = WASM.Address.from_bytes(Buffer.from(unusedAddresses[0],"hex"));
    }
    let addressPrefix = "";
    await WalletInterface.getNetworkId() == 1 ? addressPrefix="addr" : addressPrefix = "addr_test" ;
    walletAddress = changeAddress.to_bech32(addressPrefix);

    return walletAddress;
}

function getWalletIcon(walletType){
    
    switch(walletType.toLowerCase()){
        case "eternl":
            return window.cardano.eternl.icon;
        case "nami":
            return window.cardano.nami.icon;  
        case "flint":
            return window.cardano.flint.icon;
        case "gero":
            return window.cardano.gero.icon; 
    }
    
}

async function getWalletInterface(walletType) {
    
    switch(walletType.toLowerCase()){
        case "eternl":
            return await window.cardano.eternl.enable();   
        case "nami":
            return await window.cardano.nami.enable();
        case "flint":
            return await window.cardano.flint.enable();
        case "gero":
            return await window.cardano.gero.enable();   
    }

}

function isWalletInstalled(walletName){
    switch(walletName.toLowerCase()){
        case "eternl":
            
            if(window.cardano.eternl?.name != undefined)
                return true;
            else
                return false;
        case "nami":
            
            if(window.cardano.nami?.name != undefined)
                return true;
            else
                return false;
        case "flint":
            
            if(window.cardano.flint?.name != undefined)
                return true;
            else
                return false; 
        case "gero":
            
            if(window.cardano.gero?.name != undefined)
                return true;
            else
                return false;            
    }
}

async function isWalletEnabled(walletName){
    switch(walletName.toLowerCase()){
        case "eternl":
            
            return await window.cardano.eternl.isEnabled();
        case "nami":
            
            return await window.cardano.nami.isEnabled();
        case "flint":
            
            return await window.cardano.flint.isEnabled();
        case "gero":
            
            return await window.cardano.gero.isEnabled();          
    }
}

function includeWalletConnectIfAvailable(walletType,setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile) {
    
    async function setActiveWalletDetails(walletType){
        setActiveWallet("Connecting wallet...");
        setBufferWindowTrigger(true);
        setActivatedWalletType(walletType);
        let walletAddress = await getWalletAddress(walletType);
        if(walletType.toString()=="ccvault"){
            setActiveWallet("Eternl - "+walletAddress.substring(0,12));
            setActivatedWalletName("Eternl");
        }
        else if(walletType.toString()=="Flint Wallet"){
            setActiveWallet("Flint - "+walletAddress.substring(0,12));
            setActivatedWalletName(walletType.toString());
        }
        else{
            setActiveWallet(walletType.toString()+" - "+walletAddress.substring(0,12));
            setActivatedWalletName(walletType.toString());
        }
        
        setActivatedWalletAddress(walletAddress.substring(0,10)+"...."+walletAddress.slice(-10));
        setActivatedWalletIcon(getWalletIcon(walletType));
        setBufferWindowTrigger(false);
    } 

    async function onClickWrapper(){
        setConnectButton(false);
        
        if(isWalletEnabled(walletType)){
            setActiveWalletDetails(walletType);
            setIsEternlMobile("false");
        }
        else{
            try{
                await getWalletInterface(walletType);
                setActiveWalletDetails(walletType);
                setIsEternlMobile("false");
            }
            catch{
                alert("User declined action.");
            }
        }
        
    }

    async function onClickWrapperEternlMobile(){
        
        setConnectButton(false);
        
        
        
        initCardanoDAppConnectorBridge(async () => {
            if(await window.cardano.eternl.isEnabled()){
                setIsEternlMobile("true");
                setActiveWalletDetails("Eternl");
                
                console.log("eternl mobile connected");
                console.log(document.getElementById('isEternlMobile').innerHTML);
            }
            else{
                try{
                    setIsEternlMobile("true");
                    await window.cardano.eternl.enable();
                        
                    setActiveWalletDetails("Eternl");
                    
                    console.log("eternl mobile connected");
                    console.log(document.getElementById('isEternlMobile').innerHTML);
                }
                catch{
                    alert("User declined action.");
                }
            }
        });
       

    }

    
    if(isWalletInstalled(walletType)){
        if(!isWalletFound){
            setIsWalletFound(true);
        }
        let walletIcon = getWalletIcon(walletType);
        return (
            <div>
                <button className={"connectWalletButton horizontal-center"} onClick={onClickWrapper}><img src={walletIcon} width={30} height="30" style={{float:"left",backgroundColor:"white",borderRadius:"5px"}}/><div style={{lineHeight:"25px",position:"absolute",marginLeft:"auto",width:"100%"}}>{walletType.toString()}</div></button>
                <br></br>
            </div>
        );
    }
    else if(walletType=="Eternl" ){
        let isEternlMobileAviable=false;
        initCardanoDAppConnectorBridge(async () => { 
            if(isWalletInstalled("Eternl")){
                isEternlMobileAviable=true;
                if(!isWalletFound){
                    setIsWalletFound(true);
                }
            }
        })
        if(isEternlMobileAviable)
            return (
                <div>
                    <button className={"connectWalletButton horizontal-center"} onClick={onClickWrapperEternlMobile}>Eternl Wallet</button>
                    <br></br>
                </div>
            );
    }
}


async function getActivatedWalletBalance(activatedWalletType,setActivatedWalletBalance){

    const WalletRawInterface = await getWalletInterface(activatedWalletType);
    const cborBalance = await WalletRawInterface.getBalance();
    const valueBalance = WASM.Value.from_bytes(Buffer.from(cborBalance,"hex"));
    let adaAmountString = "";
    let lovelaceAmountString = valueBalance.coin().to_str();
    if (lovelaceAmountString.length>6)
            adaAmountString = lovelaceAmountString.substring(0,lovelaceAmountString.length-6)+"."+lovelaceAmountString.slice(-6);
    else
            adaAmountString = "0."+lovelaceAmountString;
    setActivatedWalletBalance(adaAmountString);
}

async function connectOrChangeWallet(setConnectButton,setWalletDropDownButton,activatedWallet,activatedWalletType,setActivatedWalletBalance){
    if(activatedWallet=="Connect wallet"){
        setConnectButton(true);
    }
    else{
        setWalletDropDownButton(true);
        getActivatedWalletBalance(activatedWalletType,setActivatedWalletBalance);
    }
}

function checkIfNoWalletsFound(isWalletFound){
    if(window.innerWidth>800 && !isWalletFound)
        return(
            <div className="normalText">This browser has no Cardano wallets extensions.</div>
        ); 
    else if(!isWalletFound)
        return(
            <div className="normalText">Please enter the dApp from a mobile wallet application.</div>
        );
}


function Header(){
    

    const [connectButton, setConnectButton] = useState(false);
    const [walletDropDownButton, setWalletDropDownButton] = useState(false);
    

    const [activatedWalletType,setActivatedWalletType] = useState(null);
    const [activatedWalletName, setActivatedWalletName] = useState("No wallet");
    const [activatedWalletAddress, setActivatedWalletAddress] = useState("No Address");
    const [activatedWallet, setActiveWallet] = useState("Connect wallet");
    const [activatedWalletIcon, setActivatedWalletIcon] = useState("");
    const [activetedWalletBalance,setActivatedWalletBalance] = useState("0");
    
    const [bufferWindowTrigger, setBufferWindowTrigger] = useState(false);
    const [isWalletFound, setIsWalletFound]=useState(false);
    const [isEternlMobile,setIsEternlMobile]=useState("false");

    let menuRef = useClickOutsite(() => setConnectButton(false),"walletsMenu");
    let dropdownRef = useClickOutsite(() => setWalletDropDownButton(false),"outSideDropDown");

    return(
        <React.Fragment>
            <div className="header-container">
                <a href="https://www.uglybrosnft.com" target="_blank" rel="noopener noreferrer"><img src={logo} className="siteLogo" width={110} height="110"></img></a>
                <img className="siteTitle" src={pageTitleImg} width={300}></img>
                <div id="connectedWallet" style={{visibility:"hidden"}}>{activatedWalletName}</div>
                <div id="isEternlMobile" style={{visibility:"hidden"}}>{isEternlMobile}</div>
                <button onClick={() => connectOrChangeWallet(setConnectButton,setWalletDropDownButton,activatedWallet,activatedWalletType,setActivatedWalletBalance)} className="connectBtn" id="connectBtn">{activatedWallet}</button>
                <WalletMenu trigger={connectButton} setTrigger={setConnectButton} ref={menuRef}>
                    <div>
                    <h3 className={"menuTitle  horizontal-center"}>Select Wallet</h3>
                    <br></br>
                    {includeWalletConnectIfAvailable("Eternl",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile)}
                    {includeWalletConnectIfAvailable("Nami",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile)}
                    {includeWalletConnectIfAvailable("Flint",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile)}
                    {includeWalletConnectIfAvailable("Gero",setBufferWindowTrigger,setConnectButton,setActiveWallet,setActivatedWalletName,setActivatedWalletAddress,setActivatedWalletIcon,setActivatedWalletType,isWalletFound,setIsWalletFound,setIsEternlMobile)}
                    {checkIfNoWalletsFound(isWalletFound)}
                    </div>
                </WalletMenu>
                <WalletDropDown trigger={walletDropDownButton} setTrigger={setWalletDropDownButton} ref={dropdownRef}>
                    <div>
                        <div>
                            <img src={activatedWalletIcon} className="walletIcon" width={60} height="60"></img>
                            <div className="walletNameFont">{activatedWalletName}</div>
                            
                            <div className="addressFont">{activatedWalletAddress}</div>
                        </div>
                        <br/>
                        <hr className="horizontalLine"/>
                        <div className="balanceFont">Balance: {activetedWalletBalance} ADA</div>
                        <br/>
                        <br/>
                    <button onClick={() => {setWalletDropDownButton(false);setConnectButton(true)}} className="changeWalletButton">Change wallet</button>
                    </div>
                </WalletDropDown>
                <BufferWindow trigger={bufferWindowTrigger} setTrigger={setBufferWindowTrigger} >
                    <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                    <div className="msgFont">Connecting wallet...</div>
                </BufferWindow>
            </div>
        </React.Fragment>
        
    )
}

export default Header;
