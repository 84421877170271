import React from "react";
import  * as WASM from  '@emurgo/cardano-serialization-lib-browser' // './local_packages/cardano-serialization-lib-nodejs/cardano_serialization_lib.d.ts';
import {Buffer} from "buffer";

import infoLogo from "../info_icon.png";
import atmImg from "../uglyATM.png";
import backgroundImg from  "../background.jpg";
import loadingIcon from "../Loading.gif";
import "./HomeComponent.css";
import PopupWindow from "./PopupWindow";
import SuccessWindow from "./SuccessWindow";
import BufferWindow from "./BufferWindow";

import { useState, useEffect } from "react";

import shroomsLogo from "../ShroomCoin_Logo.png";



let infoMsgADA = "2 ADA from this amount will be sent back with the UGLY tokens.";
let infoMsgUGLY = "Bonus ranges from 0% to 100% of the purchased amount!";

let useClickOutsite = (handler,elementClass) => {

    useEffect(() => {
        let maybeHandler = (event) => {
                if(event.target.className.toString()==elementClass.toString()){
                    handler();
                }
        };
        document.addEventListener("mousedown", maybeHandler);
           
        return () => {
            document.removeEventListener("mousedown", maybeHandler);
        };
    });
}



async function getWalletInterface(walletName){
    switch(walletName){
        case "Nami":
            return await window.cardano.nami.enable();
        case "Eternl":
            return await window.cardano.eternl.enable();
        case "Flint":
            return await window.cardano.flint.enable();
        case "Gero":
            return await window.cardano.gero.enable();
    }
}

async function buildTxAndSign(adaPaymentAmount,selectionAlgorithm){
    console.log(selectionAlgorithm)
    let linearFeeAValue="44";
    let linearFeeBValue="155381";
    const minUTxOValue="1000000";
    const maxTxSizeValue="8000";
    
    const linearFeeA = WASM.BigNum.from_str(linearFeeAValue);
    
    const linearFeeB=WASM.BigNum.from_str(linearFeeBValue);
    const linearFee = WASM.LinearFee.new(linearFeeA,linearFeeB);
    

    const txBuilderConfig = WASM.TransactionBuilderConfigBuilder.new()
    .fee_algo(linearFee)
    .pool_deposit(WASM.BigNum.from_str('500000000'))
    .key_deposit(WASM.BigNum.from_str('2000000'))
    .max_value_size(5000)
    .max_tx_size(16384)
    .coins_per_utxo_byte(WASM.BigNum.from_str('4310'))
    .build();

    
    const txBuilder = WASM.TransactionBuilder.new(txBuilderConfig);
    
    const WalletInterface = await getWalletInterface(document.getElementById("connectedWallet").innerHTML);

    //get utxos of wallet
    
    let paymentValue = WASM.Value.new(WASM.BigNum.from_str(adaPaymentAmount+"000000"));

    const utxosList = await WalletInterface.getUtxos();
    //console.log("getUtxos result: "+utxosList.length)
    let unspentTxOutputs = [];
   
    if (utxosList != undefined)
        unspentTxOutputs = utxosList.map(u => WASM.TransactionUnspentOutput.from_bytes(Buffer.from(u, 'hex' )));
    else{
        return;
    }

    let transactionUnspentOutputs=WASM.TransactionUnspentOutputs.new();
    unspentTxOutputs.forEach(utxo => {
        transactionUnspentOutputs.add(utxo);
    });

    //hardcode the node wallet
    
    let nodeAddress = WASM.Address.from_bech32("addr1vyfwg7j278k2cwjcuxnha37cwyd2pr70cl9q68sur5ae4qg5tuv0a");

    //set the changeWallet as well the readable "addr1..." address of the wallet
    const usedAddresses = await WalletInterface.getUsedAddresses();
    let changeAddress = WASM.Address.from_bytes(Buffer.from(usedAddresses[0],"hex"));
    let walletAddress = changeAddress.to_bech32("addr");

    let outputs = WASM.TransactionOutputs.new();
    let output1 = WASM.TransactionOutput.new(nodeAddress,paymentValue);
    outputs.add(output1);

    for(let i=0; i<outputs.len(); i++){
        txBuilder.add_output(outputs.get(i));
    }
    
    txBuilder.add_inputs_from(transactionUnspentOutputs,selectionAlgorithm);
    
    txBuilder.add_change_if_needed(changeAddress);

    //build tx body and add witness set to the transaction
    
    const txBody = txBuilder.build();
    
    const witnesses = WASM.TransactionWitnessSet.new();
    const transaction = WASM.Transaction.new(txBody,witnesses);
    
    const signatures = await WalletInterface.signTx(Buffer.from(transaction.to_bytes()).toString("hex"));
    const signedTx = WASM.Transaction.new(transaction.body(),WASM.TransactionWitnessSet.from_bytes(Buffer.from(signatures,"hex")));
    const txHash = await  WalletInterface.submitTx(Buffer.from(signedTx.to_bytes()).toString("hex"));

    return txHash;
}

function selectAdaPaymentAmount(setAdaPaymentAmount){
    let selectedRange = document.getElementById("zetaRangeDropMenu").value;
    setAdaPaymentAmount(selectedRange);
}

async function sendAdaToNode(adaPaymentAmount,selectionAlgorithm){

    let txHash = await buildTxAndSign(adaPaymentAmount,selectionAlgorithm);
    
    return txHash;
}

async function swapUGLY(setBufferWindowTrigger,setPopupWindowTrigger,setPopupWindowMsg,setSuccessWindowTrigger,adaPaymentAmount){

    
    //check if wallet connceted
    /*if(adaPaymentAmount=="2")
        setOption1ButtonText("Processing...");
    else
        setOption2ButtonText("Processing...");
    */
    let activatedWalletName = document.getElementById("connectedWallet").innerHTML;
    
    console.log("wallet: "+activatedWalletName);
    if(activatedWalletName == "No wallet"){
        setPopupWindowMsg("Please connnect your wallet first");
        setPopupWindowTrigger(true);
        return;
    }
    console.log("ada payment amount: "+adaPaymentAmount)
    if(parseInt(adaPaymentAmount)<3){
        setPopupWindowMsg("Minimum input is 3 ADA.");
        setPopupWindowTrigger(true);
        return;       
    }


    setBufferWindowTrigger(true);

    let atmStatus = await fetch("https://ugly-atm.adalink.io/api/get-atm-status.php?atmName=Ugly");
    
    if((await atmStatus.text()) == "off"){
        setPopupWindowMsg("ATM reserves sold out.");
        setBufferWindowTrigger(false);
        setPopupWindowTrigger(true);
        return;  
    }
    //buildTx, which will build, sign and submit
    
    let txHashValue;
    let errorMsg;
    
    await sendAdaToNode(adaPaymentAmount,0)
                .then(txHash => {txHashValue=txHash})
                .catch(err => {console.log(err); errorMsg=err});
    console.log("txHash: "+txHashValue);
    let tries=0;
    while(txHashValue==undefined && tries++<4 && errorMsg.code!=2){
        await sendAdaToNode(adaPaymentAmount,1)
                .then(txHash => {txHashValue=txHash})
                .catch(err => {console.log(err); errorMsg=err});
    console.log("txHash: "+txHashValue);
    } 

    if(txHashValue != undefined){
        /*setPopupWindowMsg("Transaction submitted successfully, Shrooms will arrive shortly.");
        setPopupWindowTrigger(true);*/
        setSuccessWindowTrigger(true);
    }
    else{
        if(errorMsg=="missing input for some native asset"){
            setPopupWindowMsg("Unsuffecient funds. The ADA in the wallet locked with native assets, please fund your wallet.");
            setPopupWindowTrigger(true);
        }
        else{
            //setPopupWindowMsg("Transaction was not submitted, please try again.");
            if(errorMsg.info != undefined)
                setPopupWindowMsg(errorMsg.info);
            else
                setPopupWindowMsg("Transaction was not submitted, please try again.");
            setPopupWindowTrigger(true);
        }
    }
        
    setBufferWindowTrigger(false);
    //return Tx hash and show it on screen
} 

function Home(){

    const [adaPaymentAmount, setAdaPaymentAmount] = useState("0");

    const [popupWindowTrigger, setPopupWindowTrigger] = useState(false);
    const [popupWindowMsg, setPopupWindowMsg] = useState("Warning Message");

    const [successWindowTrigger, setSuccessWindowTrigger] = useState(false);

    const [bufferWindowTrigger, setBufferWindowTrigger] = useState(false);
    const [bufferWindowMsg, setBufferWindowMsg] = useState("Building transaction...");

    const [option1ButtonText, setOption1ButtonText] = useState("Get Shroom coins");

    const [option2ButtonText, setOption2ButtonText] = useState("Get Shroom coins");

    

    let popupWindowRef = useClickOutsite(() => setPopupWindowTrigger(false),"outSidePopupWindow");

    
    let successWindowRef = useClickOutsite(() => setSuccessWindowTrigger(false),"outSideSuccessWindow");

    // <p>1. Read Ugly paper v2 <a className="normalLink" href="https://www.shroomcoins.io/wp-content/uploads/2022/01/Tiny-Shroom-Boom.pdf" target="_blank" rel="noopener noreferrer">"Ugly Paper V2"</a>.</p>
    return(
        <React.Fragment>
            
            
            <img className="backgroundImg" src={backgroundImg} />
            <div className="mainCointainer">
                <div className="normalText textContainer">
                    <h3><b>Welcome to Ugly ATM Page</b></h3>
                    <br/>
                    <p>You can get Ugly tokens in two easy steps:</p>
                    
                    <p>1. Connect your wallet of choice by clicking on "Connect wallet" button.</p>
                    <p>2. Enter amount below.</p>
                </div>
                <div className="atmContainer">
                    <img className="atmImg" src={atmImg}></img>
                    <div className="atmScreen">
                            <div className="atmText">
                                <div style={{marginBottom: "2%"}}>
                                <a for="uglyRange">Enter: </a>
                                <input type="number" min="3" max="1002" step="1" className="uglyRange" placeholder="0" name="uglyRange" id="uglyRangeDropMenu" onKeyDown={(event) => {if (((!/[0-9]/.test(event.key)) || document.getElementById('uglyRangeDropMenu').value.length >= 3) && (!/[\B]/.test(event.key)) ) {event.preventDefault();}}} onChange={() => setAdaPaymentAmount(parseInt(document.getElementById("uglyRangeDropMenu").value)>2?parseInt(document.getElementById("uglyRangeDropMenu").value-2):0)}>
       
                                </input>
                                <a> ADA <a className="qMark"><img src={infoLogo} width="2%" height={"2%"} /><p className="tooltiptext">{infoMsgADA}</p></a></a>
                                
                                </div>
                                <div>Amount: {adaPaymentAmount}  UGLY + Bonus <a className="qMark"><img src={infoLogo} width="2%" height={"2%"} /><p className="tooltiptext">{infoMsgUGLY}</p></a></div>
                                <button className="swapButton" onClick={() => {swapUGLY(setBufferWindowTrigger,setPopupWindowTrigger,setPopupWindowMsg,setSuccessWindowTrigger,document.getElementById('uglyRangeDropMenu').value!=""?document.getElementById('uglyRangeDropMenu').value:"0")}}>Get UGLY</button>
                            </div>
                        </div> 
                </div>
                <div className="option3Container">
                <div className="normalText">
                                <div style={{marginBottom: "25px",marginTop:"15px"}}>
                                <a for="uglyRange">Enter: </a>
                                <input type="number" min="3" max="1002" step="1" className="uglyRange" placeholder="0" name="uglyRange" id="uglyRangeDropMenu2" onKeyDown={(event) => {if (((!/[0-9]/.test(event.key)) || document.getElementById('uglyRangeDropMenu2').value.length >= 3) && (!/[\B]/.test(event.key)) ) {event.preventDefault();}}} onChange={() => setAdaPaymentAmount(parseInt(document.getElementById("uglyRangeDropMenu2").value)>2?parseInt(document.getElementById("uglyRangeDropMenu2").value-2):0)}>
       
                                </input>
                                <a> ADA <a className="qMark"><img src={infoLogo} width="12px" height={"12px"} /><p className="tooltiptext">{infoMsgADA}</p></a></a>
                                
                                </div>
                                <div>Amount: {adaPaymentAmount}  UGLY + Bonus <a className="qMark"><img src={infoLogo} width="12px" height={"12px"} /><p className="tooltiptext">{infoMsgUGLY}</p></a></div>
                                <button className="swapButton" onClick={() => swapUGLY(setBufferWindowTrigger,setPopupWindowTrigger,setPopupWindowMsg,setSuccessWindowTrigger,document.getElementById('uglyRangeDropMenu2').value!=""?document.getElementById('uglyRangeDropMenu').value:"0")}>Get UGLY</button>
                            </div>
                </div>
                <PopupWindow trigger={popupWindowTrigger} setTrigger={setPopupWindowTrigger} ref={popupWindowRef}>
                    <div className="msgFont">{popupWindowMsg}</div>
                    <button className='okButton' onClick={() => setPopupWindowTrigger(false)}>Ok</button>
                </PopupWindow>
                <BufferWindow trigger={bufferWindowTrigger} setTrigger={setBufferWindowTrigger} >
                    <img src={loadingIcon} width="50px" height={"50px"} className="bufferIcon"/>
                    <div className="msgFont">{bufferWindowMsg}</div>
                </BufferWindow>
                
                <SuccessWindow trigger={successWindowTrigger} setTrigger={setSuccessWindowTrigger} ref={successWindowRef}>
                    <button className='successOkButton' onClick={() => setSuccessWindowTrigger(false)}>Ok</button>
                </SuccessWindow>

            </div>
            <div className="normalText footerContainer">
                       <img src={shroomsLogo} width="64px" height={"64px"}></img>  Minting by <a className="normalLink" href="https://shrooms.site" target="_blank" rel="noreferrer noopener">Shrooms</a>
            </div>
        </React.Fragment>
        
    )
}

export default Home;