import React from 'react';

import Header from './HeaderComponent';


import Home from './HomeComponent';


import {Switch,Route,Redirect,withRouter} from 'react-router-dom';

function Main (){
    return(
        <React.Fragment>
            <Header/>
            <Home/>
        </React.Fragment>
        
    )

}

export default withRouter(Main);